export const names = {
    INTERPOLATION_LINE: "INTERPOLATION_LINE",
    STRAIGHT_LINE: "STRAIGHT_LINE",
    INTERSECTION: "INTERSECTION",
    COPY_TRANSFORM: "COPY_TRANSFORM",
    MIRROR_SHAPE: "MIRROR_SHAPE",
    SEAM: "SEAM",
    BUTTON: "BUTTON"
}

export default names