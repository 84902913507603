import React from 'react'

const SettingsViewSidebarPanel = () => {
    return (
        <div className='settings-view-sidebar-panel'>
            Settings view
        </div>
    )
}

export default SettingsViewSidebarPanel